import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';

import Routes from "./Routes";

import './App.css';

class App extends Component {
  render() {
    return <div className="App">
      <Navbar bg="light" variant="light" fixed="top" expand="lg">
        <Navbar.Brand className="brand" href="#top">
          <img
            alt=""
            src="https://www.operadar.app/web.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
          {' OpeRadar'}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#features">Features</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#download">Download</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#contact">Contact</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="https://OpeRadar.redbubble.com">Shop</a>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
      <Routes />
    </div>;
  }
}

export default App;
