import React, { Component } from "react";

export default class Booking extends Component {
    render() {
        return (
            <div className="Booking">
                <section className="cta" id="cta">
                    <div className="cta-content">
                        <div className="container">
                            <div className="section-heading text-center">
                                <ins class="bookingaff" data-aid="1868240" data-target_aid="1868240" data-prod="nsb" data-width="100%" data-height="auto" data-lang="en" data-df_num_properties="3">
                                    {/* <!-- Anything inside will go away once widget is loaded. --> */}
                                    <a href="//www.booking.com?aid=1868240">Booking.com</a>
                                </ins>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}