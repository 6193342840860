import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Booking from "./containers/Booking";
import Amazon from "./containers/Amazon";
import AmazonLinks from "./containers/AmazonLinks";
import DataInput from "./containers/DataInput";

export default () =>
    <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/booking" exact component={Booking} />
        <Route path="/amazon" exact component={Amazon} />
        <Route path="/amazon-links" exact component={AmazonLinks} />
        <Route path="/data-input" exact component={DataInput} />
        <Route component={Home} />
    </Switch>;
