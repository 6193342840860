import React, { Component } from "react";

export default class Amazon extends Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "//z-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1&Marketplace=DE";
        document.body.appendChild(script);
    }

    render() {
        return (
            <div className="Amazon">
            </div>
        );
    }
}