import React, { Component } from "react";

export default class AmazonLinks extends Component {
    render() {
        return (
          <div className="AmazonLinks">
            <section className="download text-center" id="features">
              <div className="container h-100">
                <h1 className="mb-5">
                  <div>
                    <a href="https://www.amazon.de/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=operadar-21&linkId=45e4a3889dab35cf5a9baa38013a14b0&language=de_DE">
                      Amazon.de
                    </a>
                  </div>
                  <div>
                    <a href="https://www.amazon.co.uk/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=operadar0d-21&linkId=a16a917bf585797a37be09f47574219c">
                      Amazon.co.uk
                    </a>
                  </div>
                  <div>
                    <a href="https://www.amazon.com/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=operadar-20&linkId=9aa971133c5748eb86055e6e9eb4070c&language=en_US">
                      Amazon.com
                    </a>
                  </div>
                  <div>
                    <a href="https://www.amazon.ca/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=operadar0c-20&linkId=7ce988ddeeb0f66935ebb151e30f583b">
                      Amazon.ca
                    </a>
                  </div>
                </h1>
                <h3>Thank you so much!</h3>
                <p className="text-muted">Thank you for supporting my business! While you are here, make sure to check out my app, and to leave a five star review in the stores.</p>
                <div className="badges">
                  <a className="badge-link" target="blank" href="https://itunes.apple.com/app/id1320612169"><img src="img/app-store-badge.svg" alt="" /></a>
                  <a className="badge-link" target="blank" href="https://play.google.com/store/apps/details?id=de.operadar.app"><img src="img/google-play-badge.svg" alt="" /></a>
                </div>
              </div>
            </section>
          </div>
        );
    }
}